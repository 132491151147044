import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  introSection,
  // description,
  row,
  col6,
  col12,
  //marginTopSubstitute,
} from "./subpage.module.scss";

const ImpressumPage = () => {
  return (
    <Layout pageTitle="Impressum" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container neutralPageWidth={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>Impressum</h1>
                <p>Dies ist eine Seite von:</p>
              </div>
            </div>

            <div className={row}>
              <div className={col6}>
                <h3>Schweiz</h3>
                <p>
                  the i-engineers AG
                  <br />
                  Badenerstrasse 141
                  <br />
                  CH-8004 Zürich
                </p>
                <p>
                  <a href="tel:+41445769246">Telefon: +41 44 576 92 46</a>
                  <br />
                  <a href="mailto:info@tie.ch">E-Mail: info@tie.ch</a>
                </p>
                <p>Eingetragener Firmenname: the i-engineers AG</p>
                <p></p>
                <p></p>
                <p></p>
                <h3>Vertretungsberechtigte Person(en)</h3>
                <p>CEO: Severin Summermatter</p>
                <p></p>
                <h3>Mehrwertsteuer-Nummer</h3>
                <p>MwSt.-Nr.&nbsp;CHE-109.863.172</p>
                <p></p>
                <h3>Unternehmens-Nr (UID)</h3>
                <p>CHE-109.863.172</p>
              </div>

              <div className={col6}>
                <h3>Deutschland</h3>
                <p>
                  the i-engineers GmbH
                  <br />
                  Hammer Straße 39
                  <br />
                  DE-48153 Münster
                </p>
                <p>
                  <a href="tel:+4925014408391">Telefon: +49 2501 440 83 91</a>
                  <br />
                  <a href="mailto:info@tie.ch">E-Mail: info@tie.ch</a>
                </p>
                <p>Eingetragener Firmenname: the i-engineers GmbH</p>
                <p></p>
                <p></p>
                <p></p>
                <h3>Vertretungsberechtigte Person(en)</h3>
                <p>Geschäftsführer Deutschland: Volker Sobieroy</p>
                <p></p>
                <h3>Handelsregister</h3>
                <p>HRB 20060, Amtsgericht Münster</p>
                <p></p>
                <h3>Umsatzsteuer-Identifikationsnummer</h3>
                <p>DE350999755</p>
              </div>
            </div>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h3>Haftungsausschluss</h3>
                <p>
                  Der Autor übernimmt keinerlei Gewähr hinsichtlich der
                  inhaltlichen Richtigkeit, Genauigkeit, Aktualität,
                  Zuverlässigkeit und Vollständigkeit der Informationen.
                  Haftungsansprüche gegen den Autor wegen Schäden materieller
                  oder immaterieller Art, welche aus dem Zugriff oder der
                  Nutzung bzw. Nichtnutzung der veröffentlichten Informationen,
                  durch Missbrauch der Verbindung oder durch technische
                  Störungen entstanden sind, werden ausgeschlossen. Alle
                  Angebote sind unverbindlich. Der Autor behält es sich
                  ausdrücklich vor, Teile der Seiten oder das gesamte Angebot
                  ohne besondere Ankündigung zu verändern, zu ergänzen, zu
                  löschen oder die Veröffentlichung zeitweise oder endgültig
                  einzustellen.
                </p>

                <h3>Haftungsausschluss für Links</h3>
                <p>
                  Verweise und Links auf Webseiten Dritter liegen ausserhalb
                  unseres Verantwortungsbereichs. Es wird jegliche Verantwortung
                  für solche Webseiten abgelehnt. Der Zugriff und die Nutzung
                  solcher Webseiten erfolgen auf eigene Gefahr des jeweiligen
                  Nutzers.
                </p>

                <h3>Urheberrechte</h3>
                <p>
                  Die Urheber- und alle anderen Rechte an Inhalten, Bildern,
                  Fotos oder anderen Dateien auf dieser Website, gehören
                  ausschliesslich der Firma the i-engineers AG oder den speziell
                  genannten Rechteinhabern. Für die Reproduktion jeglicher
                  Elemente ist die schriftliche Zustimmung des
                  Urheberrechtsträgers im Voraus einzuholen.
                </p>
                <a href="https://www.swissanwalt.ch/impressum-generator.aspx">
                  Quelle: SwissAnwalt
                </a>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default ImpressumPage;
